import React, { useState } from "react"
import Layout from "../components/layout"
import PropertyBanner from "../components/PropertyBanner/PropertyBanner"
import { Container, Modal } from "react-bootstrap"
import { graphql } from "gatsby"
import OffplanPropertyInfo from "../components/OffplanPropertyInfo/OffplanPropertyInfo"
import OffplanCardSlider from "../components/OffplanCardSlider/OffplanCardSlider"
import FormFields from "../../static/forms/offplan_brochure.json"
import DefaultForm from "../components/forms/default-form-layout"
import FormFields1 from "../../static/forms/offplan_register_new_layout.json"
import SecondaryHeader from "../components/SecondaryHeader/SecondaryHeader"
import OffplanCampaignCards from "../components/OffplanCampaignCards/OffplanCampaignCards"

const OffplanCampaignTwo = ({ data }) => {
  const offplanData = data?.strapiOffPlanCampaign

  const [show, setShow] = useState(false)
  const [show1, setShow1] = useState(false)

  const brochure =
    offplanData?.property_brochure?.length > 0
      ? offplanData?.property_brochure[0]?.url
      : ""

  const handleModal = data => {
    setShow(true)
  }
  const handleModal1 = data => {
    setShow1(true)
  }

  const handleClose1 = () => {
    setShow1(false)
  }
  const handleClose = () => {
    setShow(false)
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const formTitle = "Download the Brochure"
  const whatsapp_text = "Hi, I've come across the haus and haus Group and would like further information from you"

  const cardModule = offplanData?.offplan_modules?.find(item=>
     item.strapi_component === "page-modules.primary-module" )
  return (
    <Layout offplanNewLayout dark="dark">
      <SecondaryHeader
        bg="dark"
        whatsapp_num={offplanData?.whatsapp? offplanData?.whatsapp:'971505980269'}
        whatsapp_text={whatsapp_text}
      />
      <div className="header-top"></div>
      <PropertyBanner PageData={offplanData} offplanLayoutTwo />
      <Container>
        <OffplanPropertyInfo offplanData={offplanData} />
      </Container>
      <OffplanCampaignCards data={cardModule} offplanData={offplanData} />
      <OffplanCardSlider newlayout2 offplanData={offplanData} />

      <div className="offplan-newlayout2-btn-wrappers d-flex d-xl-none">
        <button
          onClick={() => handleModal1()}
          aria-label="Register Interest"
          className="button button-filled-green"
        >
          {/* {!isMobile&&
              <i className="icon black-mail" />
            } */}
          <span>{"Register Interest"}</span>
        </button>
        <button
          onClick={() => handleModal()}
          aria-label="Brochure"
          className="button button-filled-green"
        >
          {/* <i className="icon brochure-black-icon" /> */}
          <span>{"Brochure"}</span>
        </button>
      </div>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form news_subscribe offplan-brochure"
      >
        <Modal.Body>
          <div className="popup-form-wrapper news_subscribe">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                sourceUrl={pageurl}
                phone_id_value={"brochure_download-button"}
                fields={FormFields}
                prop_address={offplanData?.title1}
                formTitle={formTitle}
                brochureUrl={brochure}
                to_email_id={
                  offplanData?.additional_email
                    ? offplanData?.additional_email +
                      ", hauswebsiteleads@gmail.com"
                    : "hauswebsiteleads@gmail.com"
                }
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form"
      >
        <Modal.Body>
          <div className="popup-form-wrapper news_subscribe">
            <div className="close-modal" onClick={() => handleClose1()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                fields={FormFields1}
                sourceUrl={pageurl}
                prop_address={offplanData?.banner_title}
                // prop_img_url={propImage}
                formTitle="Register Interest"
                to_email_id={
                  offplanData?.additional_email
                    ? offplanData?.additional_email +
                      ", hauswebsiteleads@gmail.com, raizul@hausandhaus.com, zainul.abedeen@hausandhaus.com"
                    : "hauswebsiteleads@gmail.com, raizul@hausandhaus.com, zainul.abedeen@hausandhaus.com"
                }
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default OffplanCampaignTwo

export const query = graphql`
  query ($page_id: Int) {
    strapiOffPlanCampaign(strapi_id: { eq: $page_id }) {
      area
      banner_content {
        data {
          banner_content
        }
      }
      images {
        url
      }
      property_brochure {
        url
      }
      ggfx_results {
        src_cftle
        transforms {
          url
          transform
          format
        }
      }

      banner_title
      expected_completion_date
      district
      developer
      crm_id
      createdAt
      latitude
      longitude
      phone_number
      price
      publish
      slug1
      strapi_id
      title1
      town
      updatedAt
      whatsapp
      offplan_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          id
          title
          content {
            data {
              content
            }
          }
          add {
            content {
              data {
                content
              }
            }
          }
          strapi_component
        }
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          id
          strapi_component
          title
          description {
            data {
              description
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          id
          strapi_component
          select_module
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          id
          title
          content {
            data {
              content
            }
          }
          add {
            title
            content {
              data {
                content
              }
            }
          }
        }
          ... on STRAPI__COMPONENT_PAGE_MODULES_PRIMARY_MODULE {
          strapi_component
          title
          items {
            title
            sub_title
            image {
              url
            }
            video_url
            content {
              data {
                content
              }
            }
            cta {
              link_type
              title
              custom_link
              link {
                slug
                strapi_parent {
                  slug
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
          id
          title
          content {
            data {
              content
            }
          }
          strapi_component
          select_module
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          strapi_component
          left_right_block {
            layout
            title
            video_url
            cta_label
            menu {
              slug
              strapi_parent {
                slug
              }
            }
            description {
              data {
                description
              }
            }
            image {
              url
            }
            longitude
            latitude
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_PAYMENT_INFO {
          id
          title
          strapi_component
          content {
            data {
              content
            }
          }
          bg_image {
            url
          }
          payment_plan {
            payment_time
            payment_percent
            payment_name
          }
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_OFFPLAN_ICON {
          id
          strapi_component
          select_module
          title
          content {
            data {
              content
            }
          }
          icon_list {
            heading
            image {
              url
            }
            sub_content {
              data {
                sub_content
              }
            }
            ctas {
              title
              link_type
              link {
                slug
                strapi_parent {
                  slug
                }
              }
              custom_link
            }
          }
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          offplan_whatsapp_msg
        }
      }
    }
  }
`
