import React, { useEffect, useRef, useState } from "react"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const ReadMoreContent = ({ items }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    if (containerRef.current) {
      setIsOverflowing(containerRef.current.scrollHeight > 100)
    }
  }, [items])
  return (
    <div
      style={{
        padding: "0px",
        marginBottom: "10px",
        width: "100%",
        position: "relative",
      }}
      className="sub_content"
    >
      <div
        ref={containerRef}
        style={{
          maxHeight: isExpanded ? "none" : "200px",
          overflow: "hidden",
          transition: "max-height 0.3s ease",
          position: "relative",
        }}
      >
        {items.map((item, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            <h3>{item.title}</h3>
            <ContentModule Content={item?.description?.data?.description} />
          </div>
        ))}

        {!isExpanded && isOverflowing && (
          <div
            style={{
              position: "absolute",
              bottom: "0",
              width: "100%",
              height: "40px",
              background: "linear-gradient(transparent, white)",
            }}
          />
        )}
      </div>
      {isOverflowing && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            marginTop: "20px",
            cursor: "pointer",
            border: "none",
            textDecoration: "underline",
            background: "none",
            color: "#083819",
          }}
        >
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      )}
    </div>
  )
}

export default ReadMoreContent
